
import React, { useEffect } from 'react';
import {useCheckToken}  from "../../Utils/useCheckToken";
import { useNavigate } from "react-router-dom";

const Callback = () => {
  const isTokenExpired = useCheckToken();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the sign-in token from the URL parameters
    const params = new URLSearchParams(window.location.search);
    const signInToken = params.get('token');

    console.log('Extracted Sign-In Token:', signInToken); // Log to verify token extraction

    if (signInToken) {
      fetchApplicationToken(signInToken); // Proceed to fetch the application token
    } else {
      console.error('Sign-in token not found in the callback URL');
      window.location.href = '/signin'; // Redirect to signin
    }
  }, []);


  
 
 

  const fetchApplicationToken = async (signInToken) => {
    try {
      const response = await fetch('https://auth.decentra.net/identity/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${signInToken}`, 
        },
      });

      if (!response.ok) {
        window.location.href = '/signin'; // Redirect to signin
      }
      else
      {
        const data = await response.json();
        console.log("Data from identity token: Application Token", data);

        // Store the token 
        localStorage.setItem('bearerToken', data.accessToken);
        window.location.href = '/'; // Redirect to the matched app URL
      }
    } 
    catch (error) {
      console.error('Error fetching application token:', error);
      window.location.href = '/signin'; // Redirect to signin
    }
  };



  return (
    <div>
      Loading...
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    </div>
  );
};

export default Callback;
