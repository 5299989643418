import React, { useEffect, useState } from 'react';
import './Spinner.css';
import { useLocation } from "react-router-dom";

const RedirectToLogin = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const message = location.state?.message; // Get message from state


  useEffect(() => { 
    const callbackUrl = `${window.location.origin}/callback`;
    localStorage.setItem("callbackURL", callbackUrl);
    const loginUrl = `http://auth.decentra.net/signin/identity?callback=${encodeURIComponent(callbackUrl)}`;

    // Log current URL for debugging
    console.log("Current URL:", window.location.href);

    // Only redirect if not already on the login page
    if (!window.location.href.includes('callback')) {
      // Redirect to the login page with the callback
      console.log("Redirecting to login:", loginUrl);
      window.location.href = loginUrl;
    } else {
      // Stop loading if already on the login page
      console.log("Already on the login page, stopping loading.");
      setLoading(false);
    }

  }, []);

  return (
   
    loading ? (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div> // Show spinner while loading
    ) : null
   
  );
};

export default RedirectToLogin;
