import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import  AuthDataService  from "../DataService/AuthDataService"; 

export const useCheckToken = () => {
  // Use the service to retrieve the token
  const token = AuthDataService.getSessionToken();
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffect(() => {
    if (token) {
      try {
        const decodedJwt = jwtDecode(token);

        if (decodedJwt.exp * 1000 < Date.now()) {
          setIsTokenExpired(true);
        } else {
          setIsTokenExpired(false);
        }
      } catch (error) {
        setIsTokenExpired(true);
      }
    } else {
      setIsTokenExpired(true);
    }
  }, [token]);

  return isTokenExpired;
};
