import { useState, useEffect } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { assignApplicationToUser } from "../../DataService/ApplicationDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { notify } from "../../Utils/ReusableFunctions";
import { Application } from "./Application";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faUser} from '@fortawesome/free-solid-svg-icons';

export const AssignApplicationModal = ({
  selectedApplication,
  notifyError 
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [userList, setUserList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
     // State to track selected role IDs
    const [selectedUserIds, setSelectedUserIds] = useState([]);
  const { setCustomMessage } = useActiveInput();

  useEffect(() => {
    fetchData(ApiUrls.userApi, sessionToken).then((data) => {
      setUserList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  /* const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
  }; */

 // Function to handle checkbox change
 const handleCheckboxChange = (userId) => {
  setSelectedUserIds((prev) =>
    prev.includes(userId)
      ? prev.filter((id) => id !== userId) // Uncheck
      : [...prev, userId] // Check
  );
};


  const handleAssignApplication = () => {
    selectedUserIds.forEach((userId) => {
    assignApplicationToUser(
      ApiUrls.userApplicationApi,
      sessionToken,
      userId,
      selectedApplication.ID,
      notify,
      setCustomMessage
    );
  });
};

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvasAssignApplication"
      aria-labelledby="offcanvasAssignApplicationLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasAssignApplicationLabel">
          Assign application to user
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"    
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="mb-2" style={{ textAlign: "left" }}>
        Application Name:  {selectedApplication.Name}
        </div>
        <table className="table-container">
          <thead>
            <tr>
            <th className="text-start" style={{ width: '3%' }}></th>
            <th className="text-start" style={{ width: '40%', fontWeight: 'bold' }}>User Name</th>    
            </tr>
          </thead>
          <tbody>
            {userList.map((user) => (
              <tr key={user.ID}>
                 <td className="text-start">
              <input
                      type="checkbox"                    
                      onChange={() => handleCheckboxChange(user.ID)} // Call handler on change
                      checked={selectedUserIds.includes(user.ID)} // Keep checkbox checked state synced
                    />
              </td>
              <td className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faUser} // Role icon
                  className="myColor" // Custom color
                  style={{ marginRight: '0.5rem' }}
                />
                <span>{user.Name || 'No User Available'}</span> {/* USer name */}
              </td>              
               
              </tr>
              
            ))}
            
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-start m-3">
      <button
          type="button"
          className="btn bg-myColor mt-3"
                 
          onClick={handleAssignApplication}
          data-bs-dismiss="offcanvas"    
        >
          Assign application
        </button>
        
      </div>
    </div>
  );
};
