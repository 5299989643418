import React, { useState, useEffect } from 'react';
import { assignApplicationToUser, getUserApplications, getUserAssignedApplications } from "../../DataService/ApplicationDataService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserTag } from '@fortawesome/free-solid-svg-icons';
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { notify } from "../../Utils/ReusableFunctions";
import { toast } from 'react-toastify';

const AddApplicationAssignments = ({ selectedUser, refreshUserList}) => { 
  const [userApplications, setUserApplications] = useState([]);
  const [assignedApplications, setAssignedApplications] = useState([]); 
  const [availableApplications, setAvailableApplications] = useState([]); // Store available applications
  const [selectedApplicationIds, setSelectedApplicationIds] = useState([]);   
  const sessionToken = localStorage.getItem("bearerToken");
  
  useEffect(() => {
    const offcanvasElement = document.getElementById('offcanvasApplicationAssignments');
    const savedWidth = localStorage.getItem('offcanvasWidth') || '30%'; // Default width
  
    const updateOffcanvasWidth = () => {
      const screenWidth = window.innerWidth;
  
      // Set responsive width based on screen size
      if (screenWidth < 576) {
        offcanvasElement.style.width = '100%'; // Full width for small screens
      } else if (screenWidth < 768) {
        offcanvasElement.style.width = '60%'; // 60% width for medium screens
      } else {
        offcanvasElement.style.width = savedWidth; // Use saved width or default 30% for larger screens
      }
    };
  
    // Set saved width or default width when offcanvas is shown
    const setInitialWidth = () => {
      offcanvasElement.style.width = savedWidth;
      updateOffcanvasWidth(); // Set the width when it is shown
    };
  
   
    // Update the width on window resize
    window.addEventListener('resize', updateOffcanvasWidth);
  
    // Clean up the event listener
    return () => {
      offcanvasElement.removeEventListener('show.bs.offcanvas', setInitialWidth);
      window.removeEventListener('resize', updateOffcanvasWidth);
    };
  }, []);
  

  useEffect(() => {
    // Fetch all applications
    getUserApplications(ApiUrls.applicationApi, sessionToken, notify, (data) => {
      console.log("All Applications Data:", data);
      setUserApplications(Array.isArray(data) ? data : []);
    });

    // Fetch applications assigned to the selected user
    if (selectedUser) {     
      getUserAssignedApplications(selectedUser.ID, sessionToken, notify, (data) => {
        console.log("Assigned Applications Data:", data);
        setAssignedApplications(Array.isArray(data) ? data : []);
      });
    }
  }, [sessionToken, selectedUser]);

// Calculate available applications whenever userApplications or assignedApplications changes
useEffect(() => {
  if (userApplications.length > 0) {
    const filteredApps = userApplications.filter(app =>
      !assignedApplications.some(assignedApp => assignedApp.ID === app.ID)
    );
    setAvailableApplications(filteredApps);
    console.log("Available Applications after filtering:", filteredApps);
  }
}, [userApplications, assignedApplications]);




  // Handle checkbox change to select/unselect applications
  const handleCheckboxChange = (appId) => {
    setSelectedApplicationIds((prevSelected) => {
      if (prevSelected.includes(appId)) {
        // Unselect application
        return prevSelected.filter((id) => id !== appId);
      } else {
        // Select application
        return [...prevSelected, appId];
      }
    });
  };

  const handleAddApplicationtoUser = () => {
    if (selectedUser && selectedApplicationIds.length > 0) {  
      selectedApplicationIds.forEach((appId) => {
        const selectedApp = availableApplications.find(app => app.ID === appId);
        if (selectedApp) {
          // Assign the application to the user
          assignApplicationToUser(
            ApiUrls.userApplicationApi,
            sessionToken,
            selectedUser.ID,
            appId,
            notify,
            (message) => {
              console.log(`Application ${selectedApp.DisplayName} added:`, message);
            
              // Update assigned applications
              setAssignedApplications((prevAssigned) => [
                ...prevAssigned,
                selectedApp
              ]);  
              // Update available applications after assignment
              setAvailableApplications(prevAvailable =>
                prevAvailable.filter(app => app.ID !== appId) // Remove the assigned application
              );
            }
          );
        }
      });
      
      toast.success("Applications assigned successfully");
      refreshUserList();
    } else {
      notify("Please select at least one application to assign.");
    }
  };
  


  return (
    <div>
      {/* Table for applications */}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="text-start" style={{ width: '3%' }}></th>
              <th className="text-start" style={{ width: '60%', fontWeight: 'bold' }}>Application</th>
              <th className="text-start" style={{ width: '40%', fontWeight: 'bold' }}>Application URL</th>
            </tr>
          </thead>
          <tbody>
            {availableApplications.length > 0 ? (
              availableApplications.map((app) => (
                <tr key={app.ID}>
                  <td className="text-start">
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(app.ID)}
                      checked={selectedApplicationIds.includes(app.ID)}
                    />
                  </td>
                  <td className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faUserTag}
                      className="myColor"
                      style={{ marginRight: '0.5rem' }}
                    />
                    <span>{app.DisplayName || 'No Name Available'}</span>
                  </td>
                  <td className="text-start">{app.Url || 'Link is not Available'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  No available applications found for this user.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Add Applications Button */}
      <div className="d-flex justify-content-start">
        <button
          className="btn bg-myColor mt-3"
          style={{ width: '100px' }}
           data-bs-dismiss="offcanvas"
          onClick={handleAddApplicationtoUser}
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{ marginRight: '0.5rem' }}
          />
          Add 
        </button>
      </div>
    </div>
  );
};

export default AddApplicationAssignments;
