import { useEffect, useState, useCallback } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
} from "../../Utils/ReusableFunctions";
import { MDBTabs, MDBTabsItem, MDBTabsLink,MDBInput } from "mdb-react-ui-kit";
import { AssignApplicationModal } from "./AssignApplicationModal";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify, getProperties } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { updateApplication } from "../../DataService/ApplicationDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { useSearch } from "../../Context/SearchContext";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import{faPlus} from '@fortawesome/free-solid-svg-icons';

import { CreateApplication } from './CreateApplication';

export const Application = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [applicationList, setApplicationList] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const {searchString} = useSearch();  // Use the search string from context
 /* const [searchString, setSearchString] = useState("");*/

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    // Fetch tenant data again to refresh the list
    fetchData(ApiUrls.applicationApi, sessionToken).then((data) => {
      setApplicationList(data.value);
    });
  };
  const handleShow = () => setShowModal (true);

  const { languageOptions, setCustomMessage } = useActiveInput();
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('general'); 

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    console.log('Tab clicked:', activeTab); // Debugging
  };

   // Set the first app as selected on component mount
   useEffect(() => {
    if (applicationList.length > 0) {
      setSelectedApplication(applicationList[0]); // Set the first role as selected
    }
  }, [applicationList]);


  const handleButtonClick = () => {
    //navigate("/menu/createapplication");
    handleShow();
  };

  const handleGetProperties = useCallback(() => {
    getProperties(ApiUrls.applicationPropertiesAPi, sessionToken, languageOptions, setProperties);
  }, [sessionToken, languageOptions]);


  const notifyError = (errorMessage) => {
    notify(errorMessage);
  };

  useEffect(() => {
    fetchData(ApiUrls.applicationApi, sessionToken).then((data) => {
      setApplicationList(data.value);
      console.log(data.value);
    });

    handleGetProperties();
  }, [sessionToken, handleGetProperties]);

  const filteredList = filterListByName(applicationList, searchString);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedApplication,
        setSelectedApplication
      );
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedApplication]);

  const handleSave = () => {
    updateApplication(
      ApiUrls.applicationApi,
      sessionToken,
      selectedApplication,
      fetchData,
      setApplicationList,
      notify,
      setCustomMessage
    );
  };

  const handleChange = (field, value) => {
    setSelectedApplication((prevApplication) => ({
      ...prevApplication,
      [field]: value,
    }));
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage('');
    }
  };

  return (
    <div className="container-fluid d-flex flex-column min-vh-100 mt-0 p-0">
    <div className="row">
      {/* Heading and Add API Key Button */}
      <div className="row mb-0">
        <div className="col-lg-2 col-12 d-flex mb-0 p-0">
          <h5 className="mb-0 px-2">Applications</h5>
          <button
            className="no-border d-flex ms-auto p-0"
            style={{ width: 'auto' }}
            onClick={handleButtonClick}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="myColor"
              size="2x"
              style={{ marginRight: '0.5rem', fontSize: '1.5rem' }} // Adjust font size as necessary
            />
          </button>
        </div>
  
          {/* Tabs (if an application is selected) */}
          {selectedApplication && (
            <div className="col-lg-10 col-md-12 col-sm-12 d-none d-md-flex mb-0 align-items-center">
              <MDBTabs fill className="mb-0">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleTabClick('general')}
                    active={activeTab === 'general'}
                    className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                  >
                    General
                  </MDBTabsLink>
                </MDBTabsItem>
                {/* Add more tabs as necessary */}
              </MDBTabs>
            </div>
          )}
        </div>
  
        <div className="row">
        {/* Application Table */}
        <div className="col-lg-2 col-md-12 col-sm-12 mt-0 p-0"> 
          <div className="list-container no-spacing row g-0" style={{height:'48rem'}}>
          <div className="table-responsive">
          <table className="table table-hover table-fixed">
                <tbody>
                  {filteredList.map((item) => (
                    <tr
                      key={item.ID}
                      className={item.ID === selectedApplication?.ID ? "IdSelectedColor" : ""}
                      onClick={() => setSelectedApplication(item)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>
                        <div className="info">
                          <h6 className="truncate-text text-start">{item.Name}</h6>
                          <div className="truncate-text text-start">Code: {item.Code}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>


                {/* Responsive Tabs Display for Small Screens */}
                <div className="row d-md-none">
            <div className="col-12">
              {selectedApplication && (
                <MDBTabs fill className="mb-0">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('general')}
                      active={activeTab === 'general'}
                      className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >
                      General
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('roles')}
                      active={activeTab === 'roles'}
                      className={`custom-tab-link ${activeTab === 'roles' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >                     
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
              )}
            </div>
          </div>
  
          {/* Application Details and Tabs Content */}
          <div className="col-lg-5 col-md-12 col-sm-12 mt-3">
            {selectedApplication && (
              <div className="tab-content">
                {/* General Tab */}
                {activeTab === 'general' && (
                  <div className="tab-pane active">
                    <MDBInput
                      name="Code"
                      id="Code"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedApplication.Code}
                      onChange={(e) => handleChange("Code", e.target.value)}
                      label="Code"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="Name"
                      id="Name"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedApplication.Name}
                      onChange={(e) => handleChange("Name", e.target.value)}
                      label="Name"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="Url"
                      id="Url"
                      wrapperClass="mb-2"
                      className="form-control"
                      value={selectedApplication.Url}
                      onChange={(e) => handleChange("Url", e.target.value)}
                      label="Url"
                      type="text"
                      size="md"
                    />
                    <div className="d-flex gap-2">
                      <button
                        onClick={handleSave}
                        className="mb-3 w-50 btn bg-myColor"
                      >
                        Save
                      </button>
                   
                      <button
                          type="button"
                          className="mb-3 w-50 btn btn-secondary"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasAssignApplication"
                          aria-controls="offcanvasAssignApplication"
                        >
                          Assign Application to User
                        </button>
                        
                        <AssignApplicationModal selectedApplication={selectedApplication}/>
                   
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
  
      <ToastContainer />

      <CreateApplication show={showModal} handleClose={handleClose}/>
   
                      
    

    </div>
  );
}