export const updateUser = async (apiUrl, sessionToken, selectedUser, fetchData, setUserList, notify, setActiveInput) => {
    try {
      if (selectedUser) {
        const patchUrl = `${apiUrl}(${selectedUser.ID})`;
        const patchData = {
          Name: selectedUser.Name,
          Username: selectedUser.Username,
          Active: selectedUser.Active,
        };
  
        const response = await fetch(patchUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
          body: JSON.stringify(patchData),
        });
  
        if (response.ok) {
          console.log("User updated successfully");
          setActiveInput("userUpdate");
          fetchData(apiUrl, sessionToken).then((data) =>{
            setUserList(data.value);
          })
       
        } else {
          notify("Error updating user");
          console.error("Error updating user. Status:", response.status);
        }
      }
    } catch (error) {
      notify("Error updating user");
      console.error("Error updating user:", error);
    }
  };

  export const createUser = async (apiUrl, sessionToken, user, notify, setUser, setSelectedValue) => {
    console.log(user)
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(user),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("User created:", data);
      //  setActiveInput("userCreated");
        setUser({
          Username: "",
          Active: true,
          TenantID: "",
          Name: "",
        });
        setSelectedValue(null);
      } else {
        notify("Error creating user");
        throw Error("Failed to create user");
      }
    } catch (error) {
      notify("Error creating user");
      console.error("Error creating user:", error);
    }
  };