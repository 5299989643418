import { ApiUrls } from "./ApiUrlDataService";

export const updateApplication = async (
  apiUrl,
  sessionToken,
  selectedApplication,
  fetchData,
  setApplicationList,
  notify,
  setCustomMessage
) => {
  try {
    if (selectedApplication) {
      const patchUrl = `${apiUrl}(${selectedApplication.ID})`;
      const patchData = {
        Code: selectedApplication.Code,
        Name: selectedApplication.Name,
        Url: selectedApplication.Url,
      };

      const response = await fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      });

      if (response.ok) {
        console.log("Application updated successfully");
        setCustomMessage("Application updated successfully");
       const data = await fetchData(apiUrl, sessionToken);
       setApplicationList(data.value);
        notify("Applicaiton saved successfully!", 'success'); // Notify success
      } else {
        notify("Error updating application");
        console.error("Error updating application. Status:", response.status);
      }
    }
  } catch (error) {
    notify("Error updating application");
    console.error("Error updating application:", error);
  }
};

// to create application
export const createApplication = async (
  apiUrl,
  sessionToken,
  application,
  notify,
  setApplication,
  setCustomMessage
) => {
  try {   
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(application),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Application created:", data);
      setCustomMessage("Application created successfully");
      setApplication({
        Name: "",
        Code: "",
        Url: "",
      });
      notify("Application added successfully!", 'success'); // Notify success
    } else {
      const errorMessage = await response.text(); // Get error message from response
      notify(`Error creating application: ${errorMessage}`, 'error'); // Notify error
      throw new Error("Failed to create application");
    }
  } catch (error) {
    console.error("Error creating application:", error);
    notify("Error creating application. Please try again later.", 'error');
  }
};



//to assign application to user
export const assignApplicationToUser = async (
  apiUrl,
  sessionToken,
  selectedUserId,
  selectedApplicationId,
  notify,
  setCustomMessage
) => {
  try {
    if (selectedUserId && selectedApplicationId) {
      const assignApplicationApi = `${apiUrl}`;
      const assignData = {
        userId: selectedUserId,
        applicationId: selectedApplicationId,
      };

      const response = await fetch(assignApplicationApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(assignData),
      });

      if (response.ok) {
      //  setCustomMessage("Application assigned to user successfully")
        console.log("Application assigned to user successfully");

       // notify("Applicaiton assigned successfully!", 'success'); // Notify success
         console.log("Application assigned to user successfully");
      } else {
        console.error(
          "Error assigning application to user. Status:",
          response.status
        );
        notify("Error assigning application to user");
      }
    }
  } catch (error) {
    notify("Error assigning application to user");
    console.log("Error assigning application to user:", error);
  }
};




// Fetch applications assigned to a user
export const getUserAssignedApplications = async (userId, sessionToken, notify, setAssignedApplications) => {
  console.log(sessionToken);
  // Corrected API URL
  const api = `${ApiUrls.userAssignApplicationApi}?$filter=UserID eq ${userId}&$expand=Application($select=DisplayName,Url)`;
  console.log("API URL:", api); // Log API URL
  console.log("User ID:", userId); // Log User ID

  try {
    const response = await fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log("UserAssigned application API Response Data:", data); // Log response data

      // Ensure data is an array and set it in state
      setAssignedApplications(data.value || []); // Use `data.value` directly
    } else {
      console.error("Error fetching assigned applications. Status:", response.status);
      notify("Error fetching assigned applications.");
    }
  } catch (error) {
    console.error("Error fetching assigned applications:", error);
    notify("Error fetching assigned applications.");
  }
};



/// Fetch all user applications
export const getUserApplications = async (
  apiUrl,
  sessionToken,
  notify,
  setAppList
) => {
  try {
    // Fetch the list of applications
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses (like 404, 500)
      const errorText = await response.text();
      console.error("Error fetching user applications:", response.status, errorText);
      notify("Error fetching user applications: " + errorText);
      return;
    }

    // Parse the response as JSON
    const data = await response.json();
    const allApplications = data.value || [];
    console.log("All UserApplications API Response Data:", allApplications); // Log response data

    // Set the app list state
    setAppList(allApplications);
  } catch (error) {
    console.error("Error fetching applications:", error);
    notify("Error fetching applications");
  }
};


// Delete assigned application
export const deleteUserAssignedApplications= async (selectedAppIds, sessionToken, notify, setAssignedApplication, userId) => {
  if (!selectedAppIds || selectedAppIds.length === 0) {
    notify("No application selected for deletion.");
    return;
  }

  // Show confirmation dialog
  const confirmDelete = window.confirm("Are you sure you want to delete the selected applications?");
  if (!confirmDelete) {
    // User canceled the deletion
    return;
  }

  try {
    // Loop through each selected app ID and send a DELETE request
    for (const appId of selectedAppIds) {
      const api = `${ApiUrls.deleteUserApplicationtApi}${appId}`;
      
      const response = await fetch(api, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`
        }
      });

      if (!response.ok) {
        console.error(`Error deleting app ID ${appId}. Status:`, response.status);
     //  notify(`Error deleting role ID ${roleId}.`);
        return;
      }
    }


  } catch (error) {
    console.error("Error deleting Applications:", error);
    notify("Error deleting Applications.");
  }
};