import { useState, useEffect } from "react";
import logo from "../../Images/full_logo.svg";
import "./LoginAuth.css";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AuthDataService from "../../DataService/AuthDataService";
import {  InputGroup, FormControl, Button } from 'react-bootstrap'; // Import from react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap"; //to create forgot password modal
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import "./RedirectToLogin.js";


const diffMethods = [
  {
    label: "Text Message",
    value: 1,
  },
  {
    label: "Email",
    value: 2,
  },
  {
    label: "Authentication app",
    value: 4,
  },
];



export const LogInAuth = () => {
  const navigate = useNavigate();

  const sessionToken = AuthDataService.getSessionToken();
  const [showMFA, setShowMFA] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const mfaToken = localStorage.getItem("mfaToken");
  const [newTokenMethod, setNewTokenMethod] = useState(false);

  const [showForgotPassword, setShowForgotPassword] = useState(false); //forgot password
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

// Handle form submission for forgot password
const handleForgotPassword = async() => {
  console.log('Sending password reset request for:', forgotPasswordEmail);
  try {
    const response = await fetch('https://auth.decentra.net/identity/lostpassword', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(forgotPasswordEmail),
  });

    if (response.ok) {
      toast.success('Password reset link sent to your email!');
      setShowForgotPassword(false); // Close the modal after sending
    } else {
      toast.error('Error sending password reset link. Please try again.');
    }
  } catch (error) {

    console.error('Error:', error);
 toast.error('An error occurred while processing your request.');
  }
}; 
   
  const initState = {
    userID: "",
    password: "",
  };
  const [state, setState] = useState(initState);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const [selectedMethod, setSelectedMethod] = useState("");

  const handleChangeMethod = (event) => {
    setSelectedMethod(event.target.value);
    console.log(event.target.value);
  };

  async function SignIn() {
    try {      
      await AuthDataService.signIn(
        state.userID,
        state.password,
        setShowMFA,
        navigate
        
      );
      
    } catch (error) {
      notify("Error logging in");
    }
    console.log("Login button clicked or Enter key pressed");
    
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default form submission
      SignIn(); // Call SignIn function
    }
  };

  const verifyUser = async () => {
    console.log(mfaToken);
    const verifyData = verificationCode;
    console.log(verifyData);
    const response = await fetch("https://auth.decentra.net/identity/mfa", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${mfaToken}`,
      },
      body: JSON.stringify(verifyData),
    });

    if (response.status === 200) {
      console.log("Verification successfull");
      const responseData = await response.json();    
     
      const sessionToken = responseData.accessToken;
    
      localStorage.setItem("bearerToken", sessionToken);
  
      // Redirect to the callback URL with the sign-in
      const params = new URLSearchParams(window.location.search);
      const callUrl = params.get('callback')
      const callbackUrl = `${callUrl}?token=${sessionToken}`;
      window.location.href = callbackUrl; 
    } else {
      console.log("Login failed");
      throw new Error("Login failed");
    }
  };

  const altMethodToVerify = async () => {
    const url = `https://auth.decentra.net/identity/mfa/${selectedMethod}`;
    console.log(url);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${mfaToken}`,
      },
    });

    if (response.status === 200) {
      console.log("Verification successfull");
      const responseData = await response.json();
      console.log(responseData);
      const mfaToken = responseData.mfaToken;
      console.log(mfaToken);
      localStorage.setItem("mfaToken", mfaToken);
      setNewTokenMethod(false);
    } else {
      console.log("Login failed");
      throw new Error("Login failed");
    }
  };

 
  return (
    <div className="loginContainer">
      
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center ">
          <MDBCol col="12">
            <MDBCard
              className="text-white my-5 mx-auto"
              style={{
                borderRadius: "1rem",
                maxWidth: "400px",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            >
              <MDBCardBody className="p-5 d-flex flex-column align-items-center mx-auto w-100">
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <h3
                    className="fw-bold mb-2 text-uppercase"
                    style={{ flex: 1, textAlign: "left" }}
                  >
                    Login
                  </h3>
                  <img
                    src={logo}
                    alt="LoginImage"
                    style={{ height: "60px", paddingBottom: 10 }}
                  />
                </div>
                <hr></hr>
                {!showMFA && (
                  <>
                  <InputGroup className="mb-3 mx-5 w-100">
                      <InputGroup.Text className="bg-transparent text-white border-white">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <FormControl
                        name="userID"
                        className="bg-transparent text-white"
                        value={state.userID}
                        onChange={handleChange}
                        placeholder="Email address"
                        type="email"
                        onKeyDown={handleKeyDown}
                      />
                    </InputGroup>

                    <InputGroup className="mb-3 mx-5 w-100">
                      <InputGroup.Text className="bg-transparent text-white border-white">
                        <FontAwesomeIcon icon={faLock} />
                      </InputGroup.Text>
                      <FormControl
                        name="password"
                        className="bg-transparent text-white"
                        value={state.password}
                        onChange={handleChange}
                        placeholder="Password"
                        type="password"
                        onKeyDown={handleKeyDown}
                      />
                    </InputGroup>
                 

                    <MDBBtn
                      outline
                      className="mx-2 px-5 w-100 border-white text-white"
                      color="white"
                      onClick={SignIn}                    
                      type="submit"
                    >
                      Login
                    </MDBBtn>
                    <p className="text-center mt-4 mb-0">
                      <a className="text-light" style={{ textDecoration: 'underline', cursor:'pointer'}} onClick={() => setShowForgotPassword(true)}>
                        Forgot Password?
                      </a>
                    </p>     
                                 
                {/* Forgot Password Modal */}
                <Modal show={showForgotPassword} onHide={() => setShowForgotPassword(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Password Reset</Modal.Title>
                  </Modal.Header>
                  
                  <Modal.Body>
                    {/* Using InputGroup with an icon, similar to the earlier one */}
                    <InputGroup className="mb-3 w-100">
                      <InputGroup.Text className="bg-transparent text-dark border">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <FormControl
                        name="forgotPasswordEmail"
                        className="bg-transparent text-dark"
                        value={forgotPasswordEmail}
                        onChange={(e) => setForgotPasswordEmail(e.target.value)}
                        placeholder="Enter Email Address"
                        type="email"                        
                      />
                    </InputGroup>
                  </Modal.Body>                                    
                  <Modal.Footer>                     
                      <Button 
                        variant="primary" 
                        onClick={handleForgotPassword} 
                        style={{ cursor: 'pointer' }}                        
                      > Send Reset Link
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>                
              )}
                
          

                {showMFA && !newTokenMethod && (
                  <>
                    <MDBInput
                      name="verificationCode"
                      wrapperClass="mb-3 mx-5 w-100"
                      className="bg-transparent text-white"
                      labelClass="text-white"
                      value={verificationCode}
                      onChange={(event) =>
                        setVerificationCode(event.target.value)
                      }
                      label="Verification Code"
                      type="text"
                      size="md"
                    />
                    <MDBBtn
                      outline
                      className="mx-2 px-5 w-100 border-white text-white"
                      color="white"
                      onClick={verifyUser}
                      size="sm"
                      type="submit"
                    >
                      Verify
                    </MDBBtn>

                    <p className="small mb-3 pb-lg-2 mt-5">
                      <span
                        className="text-white-50"
                        style={{ cursor: "pointer" }}
                        onClick={() => setNewTokenMethod(true)}
                      >
                        Get verification with different method
                      </span>
                    </p>
                  </>
                )}

                {newTokenMethod && (
                  <>
                    <div className="">
                      <div className="w-100 mb-2" style={{ textAlign: "left" }}>
                        Choose alternative method for login
                      </div>
                      <div className="w-75 mb-4 ">
                        <select
                          className="form-select form-select-sm"
                          aria-label="Default select example"
                          value={selectedMethod}
                          onChange={handleChangeMethod}
                        >
                          <option value="" disabled>
                            Select method
                          </option>
                          {diffMethods.map((method) => (
                            <option key={method.value} value={method.value}>
                              {method.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <MDBBtn
                          outline
                          className="mx-2 px-5 border-white text-white"
                          color="white"
                          onClick={altMethodToVerify}
                          size="sm"
                          type="submit"
                        >
                          Get verification code
                        </MDBBtn>
                      </div>
                    </div>
                  </>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ToastContainer />
    </div>
  );
};
