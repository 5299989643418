import React, { useState, useEffect } from 'react';
import { getFilteredRoles, assignRoleToUser, getUserAssignedRoles } from "../../DataService/RolesDataService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faUserTag } from '@fortawesome/free-solid-svg-icons';
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { notify } from "../../Utils/ReusableFunctions";
import { toast } from 'react-toastify';


const AddRoleAssignments = ({ selectedUser, refreshUserList }) => {
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleDescription, setSelectedRoleDescription] = useState('');
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [assignedRoles, setAssignedRoles] = useState([]);
   // State to track selected role IDs
   const [selectedRoleIds, setSelectedRoleIds] = useState([]);
  const sessionToken = localStorage.getItem("bearerToken");
  

  useEffect(() => {
    // Fetch all roles
    getFilteredRoles(ApiUrls.filteredRoleApi, [1], sessionToken, notify, setFilteredRoles);
  
    // Fetch roles assigned to the selected user
    if (selectedUser) {
      getUserAssignedRoles(selectedUser.ID, sessionToken, notify, (data) => {       
        setAssignedRoles(Array.isArray(data) ? data : []);
        console.log("Assigned Roles Data:", data);
      });
     
    }
  }, [sessionToken, selectedUser]);

    // Filter roles that are not assigned to the user
    const offCanvasRoles = filteredRoles.filter(role =>
      !assignedRoles.some(assignedRole => assignedRole.ID == role.ID)
    );


   //off-canvas width 
   useEffect(() => {
    const offcanvasElement = document.getElementById('offcanvasRoleAssignments');
    const savedWidth = localStorage.getItem('offcanvasWidth') || '30%'; // Default width

    const updateOffcanvasWidth = () => {
      const screenWidth = window.innerWidth;
      
      // Set responsive width based on screen size
      if (screenWidth < 576) {
        offcanvasElement.style.width = '100%'; // Full width for small screens
      } else if (screenWidth < 768) {
        offcanvasElement.style.width = '60%'; // 60% width for medium screens
      } else {
        offcanvasElement.style.width = savedWidth; // Use saved width or default 30% for larger screens
      }
    };
  
     // Set saved width or default width when offcanvas is shown
     offcanvasElement.addEventListener('show.bs.offcanvas', () => {
      offcanvasElement.style.width = savedWidth;
      updateOffcanvasWidth(); // Set the width when it is shown
    });

    // Update the width on window resize
    window.addEventListener('resize', updateOffcanvasWidth);
  
    // Clean up the event listener
    return () => {
      offcanvasElement.removeEventListener('show.bs.offcanvas', updateOffcanvasWidth);
      window.removeEventListener('resize', updateOffcanvasWidth);
    };
  }, []);
  
  // Function to save the width when needed
  const saveWidth = (width) => {
    localStorage.setItem('offcanvasWidth', width);
    const offcanvasElement = document.getElementById('offcanvasRoleAssignments');
    offcanvasElement.style.width = width; // Dynamically apply the new width
  };
  
// Handle checkbox change to select/unselect roles
const handleCheckboxChange = (roleId) => {
  setSelectedRoleIds((prevSelected) => {
    if (prevSelected.includes(roleId)) {
      // Remove role ID if it's already selected (uncheck)
      return prevSelected.filter((id) => id !== roleId);
    } else {
      // Add role ID if not already selected (check)
      return [...prevSelected, roleId];
    }
  });
};


 
  // Handle adding roles to the user
  const handleAddRoletoUser = () => {
    if (selectedUser && selectedRoleIds.length > 0) {
      selectedRoleIds.forEach((roleId) => {
        const selectedRole = availableRoles.find((role) => role.ID === roleId);
        if (selectedRole) {
          assignRoleToUser(
            ApiUrls.userRoleApi,
            sessionToken,
            selectedUser.ID,
            roleId,
            notify,
            (message) => {            
              console.log(`Role ${selectedRole.DisplayName} added:`, message);

                 // Reset selectedRoleIds to an empty array to deselect all checkboxes
                  setSelectedRoleIds([]);
                  refreshUserList();
            }
          );
        }
      });
     

      toast.success("Role assigned successfully"); // Display success message
      
      
      
    } 
    else {
      notify("Please select at least one role to assign.");
    }
  };



 // Filter roles (those not assigned to the user)
 const availableRoles = filteredRoles.filter(role =>
  !assignedRoles.some(assignedRole => assignedRole.ID === role.ID)
);


return (
  <div>
    {/* Table for roles */}
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th className="text-start" style={{ width: '3%' }}></th>
            <th className="text-start" style={{ width: '40%', fontWeight: 'bold' }}>Role</th>
            <th className="text-start" style={{ width: '50%', fontWeight: 'bold' }}>Description</th>
          </tr>
        </thead>
        <tbody>  

          {availableRoles.map((role) => (
            <tr key={role.ID}>
              <td className="text-start">
              <input
                      type="checkbox"                    
                      onChange={() => handleCheckboxChange(role.ID)} // Call handler on change
                      checked={selectedRoleIds.includes(role.ID)} // Keep checkbox checked state synced
                    />
              </td>
              <td className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faUserTag} // Role icon
                  className="myColor" // Custom color
                  style={{ marginRight: '0.5rem' }}
                />
                <span>{role.Name || 'No Role Available'}</span> {/* Role name */}
              </td>
              <td className="text-start">
                {role.Description || 'No Description Available'} {/* Role description */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    {/* Add Role Assignments Button */}
    <div className="d-flex justify-content-start">
    <button
      className="btn bg-myColor mt-3"
      style={{ width: '100px' }} 
      data-bs-dismiss="offcanvas"  
      onClick={handleAddRoletoUser}     
    >
      <FontAwesomeIcon
        icon={faPlus}
        style={{ marginRight: '0.5rem' }}
      />
      Add 
    </button>
 
    </div>

    {/* Off-Canvas for Role Assignments */}
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvasRoleAssignments"
      aria-labelledby="offcanvasRoleAssignmentsLabel"
    >
      <div className="offcanvas-header">
        <h5 id="offcanvasRoleAssignmentsLabel">Assign Role to User</h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      
      <div className="offcanvas-body">
        {/* Table to show assigned roles in offcanvas with description and checkboxes */}
       
        </div>
      </div>

      
   
    </div>   
  
);
}
export default AddRoleAssignments;


