import React, { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes,faUserTag } from '@fortawesome/free-solid-svg-icons';
import AddApplicationAssignment from './AddApplicationAssignment';
import { deleteUserAssignedApplications, getUserApplications, getUserAssignedApplications, getFilteredApplications } from "../../DataService/ApplicationDataService";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import {
  fetchData,  
  notify,
  truncateText,
} from "../../Utils/ReusableFunctions";
import "./User.css";


const ApplicationTab = ({ selectedUser, sessionToken,refreshUserList }) => {   
 // const sessionToken = localStorage.getItem("bearerToken"); 
 const [userList, setUserList] = useState([]);
 const [setSelectedUser] = useState(null);
 const [selectedApplicationIds, setSelectedApplicationIds] = useState([]);
 const [activeTab, setActiveTab] = useState('Add application assignments');


 useEffect(() => {
  if (selectedApplicationIds.length > 0) {
    handleTabClick('Remove application assignments'); // Activate the tab
  } else {
    handleTabClick('Add application assignments'); // Or set to a default tab if no roles are selected
  }
}, [selectedApplicationIds]); // Run this effect whenever selectedRoleIds changes

  // Handler for setting active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Handle adding a new application assignment
const handleAddTabClick = () => {
  console.log('AddApplicationAssignment window opens');
  console.log('Selected User:', selectedUser);
  };

 // Fetch user assigned applications
 useEffect(() => {
  if (selectedUser) {
    console.log(sessionToken);
    getUserAssignedApplications(selectedUser.ID, sessionToken, notify, (data) => {
      console.log("Fetched assigned applications:", data);
      setUserList(data);
      console.log("Updated userList state:", data); 
    });
  }
}, [sessionToken, selectedUser]);



// Handle checkbox selection
const handleCheckboxChange = (appId) => {
  setSelectedApplicationIds((prevSelected) => {
   if( prevSelected.includes(appId)){
      return prevSelected.filter((id) => id !== appId);
   }else{
    return [...prevSelected, appId];
   }
     
});
};
 console.log(selectedUser);







const handleDeleteTabClick = async () => {
    if(selectedApplicationIds.length===0){
      console.log("check the app to delete");
      notify("Select the apps to delete");
    }
    if (selectedApplicationIds.length > 0) {
      try {
        await deleteUserAssignedApplications(selectedApplicationIds, sessionToken, notify);
        notify('Apps successfully deleted!', 'success');
        console.log("app deleted",selectedApplicationIds);
        setActiveTab('Add application assignments'); 

    // Call the refresh function to update the list in User.js
    refreshUserList();
   
    } catch (error) {
      notify('Failed to delete roles', 'error');
    }
  };  

  }
  // Render the table for applications

  console.log(activeTab)
  return (
    <div className='tab-pane mt-3 mb-2'>
      <table cellPadding="0" cellSpacing="0" style={{ width: '100%' }}>
        <thead style={{ borderBottom: '1px solid #e0e0e0' }}>
          <tr>
            <td className='text-start mb-3' style={{ padding: '0' }}>
              <div className='d-flex justify-content-start' style={{ gap: '0' }}>
                <button
                  className='no-border d-flex align-items-center'
                  style={{ width: 'auto', padding: '0', margin: '0' }}
                  type='button'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasApplicationAssignments'
                  onClick={() => {
                    handleTabClick('Add application assignments');
                    handleAddTabClick();
                  }}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={activeTab === 'Add application assignments' ? 'myColor' : 'TabIconColor'}
                    style={{ marginRight: '0.5rem', fontWeight: 'normal', fontSize: '24px' }}
                    size='2x'
                  />
                 <p style={{ margin: '0',
                   lineHeight: '1.5rem',
                    fontSize: '14px',
                     color: activeTab === 'Add application assignments' ? 'black' : 'grey', // Change label color 
                  }}>Add application assignments</p>
                </button>

                <button
                  className='no-border d-flex align-items-center'
                  style={{ width: 'auto', padding: '0', margin: '0' }}
                  onClick={() => {
                   // handleTabClick('Remove application assignments');
                    handleDeleteTabClick();// Cal function for deleting an app
                  }}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={activeTab === 'Remove application assignments' ? 'myColor' : 'TabIconColor'}
                    style={{ marginLeft: '2rem', marginRight: '0.5rem', fontSize: '24px' }}
                    size='2x'/>
                    
                    <p
                      style={{
                        margin: '0',
                        lineHeight: '1.5rem',
                        fontSize: '14px',
                        color: activeTab === 'Remove application assignments' ? 'black' : 'grey', // Change label color
                      }}
                    >Remove application assignments</p>
                </button>
              </div>
            </td>
          </tr>
        </thead>
      </table>

      {/* Table to show assigned applications with URLs */}
      <div className="table table-fixed table-responsive truncate-text">
        <table className='table-responsive'>
          <thead>
            <tr>
              <th className='text-start'></th>
              <th className='text-start' style={{ width: '40%', fontWeight: 'bold' }}>Application</th>
              <th className='text-start' style={{ fontWeight: 'bold', width: '60%' }}> URL</th>
            </tr>
          </thead>
          <tbody>
          {userList && userList.length > 0 ? (
            userList.map((app) => (              
              <tr key={app.ID}>
                <td className='text-start'>
                  <input
                    type='checkbox'
                    style={{ marginRight: '0.5rem' }}
                    onChange={() => handleCheckboxChange(app.ID)}
                    checked={selectedApplicationIds.includes(app.ID)}
                  />
                </td>
                <td className='d-flex align-items-center'>
                  <FontAwesomeIcon
                    icon={faUserTag}
                    className='myColor'
                    style={{ marginRight: '0.5rem' }}
                  />
                  <span>{app.Application?.DisplayName ? truncateText(app.Application?.DisplayName, 50) : 'No Name Available'}</span> {/* Display Application Name */}
                </td>
                <td className='text-start truncate'>{app.Application?.Url ? truncateText(app.Application?.Url, 50) : 'No URL Available'}</td> {/* Display Application URL */}
              </tr>
            ))
          ) : (
            <tr>
            <td colSpan="3" className='align-items-center'>
                No assigned applications found for this user.
                </td>
          </tr>
          )}
        </tbody>

        </table>
      </div>

      {/* Off-canvas for AddApplicationAssignments */}
      <div
        className='offcanvas offcanvas-end'
        tabIndex='-1'
        id='offcanvasApplicationAssignments'
        aria-labelledby='offcanvasApplicationAssignmentsLabel'
      >
        <div className='offcanvas-header'>
          <h5 id='offcanvasApplicationAssignmentsLabel'>Assign Application to User</h5>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          ></button>
        </div>
        <div className='offcanvas-body'>
          {/* AddApplicationAssignments component */}
          <AddApplicationAssignment
            selectedUser={selectedUser}          
           refreshUserList={refreshUserList}
           userList={userList}
       
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ApplicationTab;