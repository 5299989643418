import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from "jwt-decode";
import { notify, getProperties } from "../../Utils/ReusableFunctions";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { useSearch } from "../../Context/SearchContext";
import { SideBar } from './SideBar';
import logo from '../../Images/DECENTRAlogo_white_transperent.png';

export const NavigationBar = () => {
  const { languageOptions = [], setLanguageOptions } = useActiveInput() || {};
  const navigate = useNavigate();
  const location = useLocation();
  const searchInputRef = useRef(null); // Reference for search input
  const [userInfo, setUserInfo] = useState({ initials: '', name: '' });
  const [isOpen, setIsOpen] = useState(false); // Sidebar visibility
  const [searchOpen, setSearchOpen] = useState(false);
  const { searchString, setSearchString } = useSearch();

  // Get user initials from username
  const getInitials = (username) => {
    return username ? username.split(" ").map((name) => name[0]).join("").toUpperCase() : "";
  };

  // Fetch user information from local storage
  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    const initials = storedUserName ? getInitials(storedUserName) : 'NA';
    setUserInfo({ name: storedUserName || 'User', initials });
  }, [localStorage.getItem("bearerToken")]);

  // Clear search input field on location change
  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.value = ''; // Clear the input field
    }
    setSearchString(''); // Clear the search string in the search context
  }, [location, setSearchString]);

  // Toggle visibility of search bar
  const toggleSearchBar = () => {
    setSearchOpen(prev => !prev);
    if (!searchOpen) {
      searchInputRef.current.focus(); // Focus on input when opened
    }
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => setIsOpen(prev => !prev);

  // Logout function
  const logOut = () => {
    const sessionToken = localStorage.getItem("bearerToken");
    if (!sessionToken) {
      notify("User is already logged out");
      navigate("/signin");
      return;
    }
    localStorage.removeItem("bearerToken");
    console.log("User has been logged out");
    navigate("/signin");
  };

  // Get user ID from JWT token
  const token = jwtDecode(localStorage.getItem("bearerToken"));
  const setID = token.deauth_user;

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-myColor">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          {/* Brand and Toggle Button Container */}
          <div className="d-flex align-items-center">
            {/* Navbar Brand */}
            <div className="navbar-brand">
              {/* Toggle Button for Sidebar (Visible on small screens) */}
              <button className="navbar-toggler ms-auto d-lg-none" onClick={toggleSidebar} aria-label="Toggle sidebar">
                <span className="navbar-toggler-icon" />
              </button>
              {/* Logo */}
              <img src={logo} alt="Logo" className="logo-img" style={{ height: '100%', maxHeight: '50px', width: 'auto' }} />
            </div>
          </div>

          {/* Toggle Button for Search Bar */}
          <button
            className="navbar-toggler ms-auto d-lg-none"
            type="button"
            onClick={toggleSearchBar}
            aria-controls="navbarSupportedContent"
            aria-expanded={searchOpen}
            aria-label="Toggle search"
          >
            <span className="navbar-toggler fas fa-search lg-none" style={{ marginLeft: '5rem' }} />
          </button>

          {/* Avatar and Dropdown for Small Screens */}
          <div className="d-lg-none">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="circle-dropdown-toggle lg-none"
                style={{ backgroundColor: 'transparent', border: 'none', padding: 0,boxShadow: 'none' }}
                bsPrefix="toggle-custom"
              >
                <Avatar style={{
                    backgroundColor: '#e0e0e0',
                    color: '#ffffff',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    borderRadius: '50%',
                    marginLeft:'0.5rem'
                  }}>
                  <FontAwesomeIcon icon={faUser} style={{ fontSize: '20px', color: '#80cbc4' }} />
                </Avatar>
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item onClick={() => navigate(`/menu/userprofile/${setID}`)}>Profile</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={logOut}>Log out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* Navbar Collapsible Content */}
          <div className={`collapse navbar-collapse ${searchOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" />
            {/* Search Bar Container */}
            <div className="box d-lg-flex" style={{ position: 'relative', alignItems: 'center' }}>
              <input
                className="form-control"
                type="search"
                value={searchString}
                placeholder="Search"
                aria-label="Search"
                ref={searchInputRef}
                onChange={(e) => setSearchString(e.target.value)}
                style={{ paddingRight: '40px' }}
              />
              <i
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  fontSize: '20px',
                  cursor: 'pointer',
                }}
                className="fas fa-search"
                onClick={toggleSearchBar}
              />
            </div>

            {/* Avatar and Dropdown for Larger Screens */}
            <div className="d-none d-md-flex">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="circle-dropdown-toggle"
                  style={{ backgroundColor: 'transparent', border: 'none', padding: 0 ,boxShadow: 'none'}}
                  bsPrefix="toggle-custom"
                >
                  <Avatar style={{
                    backgroundColor: '#e0e0e0',
                    color: '#ffffff',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    borderRadius: '50%',
                    marginLeft:'0.5rem'
                  }}>
                    <FontAwesomeIcon icon={faUser} style={{ fontSize: '20px', color: '#80cbc4' }} />
                  </Avatar>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item onClick={() => navigate(`/menu/userprofile/${setID}`)}>Profile</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={logOut}>Log out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar Component */}
      <SideBar isOpen={isOpen} toggleSidebar={toggleSidebar} offcanvasSidebar={toggleSidebar} />
    </div>
  );
};
