
import "./App.css";
import { LogInAuth } from "./Components/Authentication/LogInAuth";
import  RedirectToLogin from "./Components/Authentication/RedirectToLogin";
import  Callback from "./Components/Authentication/Callback";
import { BrowserRouter as Router, Routes, Route, Switch, Link } from "react-router-dom";
import { ApplicationContainer } from "./Components/Navigation/ApplicationContainer";
import { PrivateRoute } from "./Components/Navigation/PrivateRoute";

import React, { useState,useEffect } from "react";
import { ActiveInputProvider } from './Context/ActiveInputTooltip';
import { SearchProvider } from './Context/SearchContext';
import { useCheckToken } from "./Utils/useCheckToken";
import { useNavigate } from "react-router-dom";


function App() {   


  console.log('App component rendering');


 
  return (
    <ActiveInputProvider> 
       <SearchProvider>
      <Router> 
        <div className="App"> 
        {/* Routing component */}       
          <Routes>        
        {/* Redirect to the common login page when accessing the app */}
            <Route path="/signin" element={<RedirectToLogin />} />
            <Route path="/signin/identity" element={<LogInAuth />} />
            <Route path="/callback" element={<Callback />} />
     

        {/* The home page after successful login */}
          {/* Private route for authenticated users */}
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<RedirectToApp />} />
              <Route path="/menu/*" element={<ApplicationContainer />} />    
            </Route>

              {/* Catch-all for 404 Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>

        </div>
        
      </Router>

      </SearchProvider>
</ActiveInputProvider>

    
  );
}

//If authentication go to the Homepage
const RedirectToApp = () => {
  useEffect(() => { 
    window.location.href = "/menu/users";
  }, []);
}

function NotFound() {
  return (
    <div>
      <h2>404 - Not Found</h2>
      Go to <Link to="/signin">Login</Link> page
    </div>
  );
}

export default App;
